<template>
  <div>
    <b-sidebar
      id="sidebar-condition"
      ref="sidebarCondition"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-condition-header"
      right
      backdrop
      shadow
      no-header
      width="80vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter"> Conditions </b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>
        <div v-if="isLoading">
          <OtherLoading />
        </div>
        <div v-else>
          <div class="mt-3">
            <b-form-radio-group v-model="isSelected" @change="setCondition">
              <b-row>
                <b-col
                  ><b-form-radio :value="2">Unconditional</b-form-radio></b-col
                >
                <b-col><b-form-radio :value="1">Custom</b-form-radio></b-col>
              </b-row>
            </b-form-radio-group>
          </div>
          <div v-if="isSelected === 1">
            <b-row class="mt-3">
              <b-col md="4">
                <label>User Type <span class="text-error">*</span></label>
              </b-col>
              <b-col md="8">
                <label
                  >Show Information <span class="text-error">*</span></label
                >
              </b-col>
            </b-row>

            <div class="mt-3" v-for="(item, index) in form" :key="index">
              <b-row
                v-if="
                  item.condition_type_id < 12 ||
                  item.condition_type_id == 30 ||
                  item.condition_type_id == 31 ||
                  item.condition_type_id == 17 ||
                  item.condition_type_id == 34
                "
              >
                <b-col md="4">
                  <InputSelectAutomation
                    v-bind:options="conditionList"
                    :value="item.condition_type_id"
                    :v-model="item.condition_type_id"
                    :indexCondition="index"
                    @onDataChange="selectCondition"
                    valueField="id"
                    textField="name"
                  />
                </b-col>
                <b-col md="8">
                  <b-row v-if="item.condition_type_id === 1">
                    <b-col md="10">
                      <InputSelectAutomation
                        v-bind:options="memberLevel"
                        :value="item.condition_value"
                        :v="$v.form.$each.$iter[index].condition_value"
                        :isValidate="
                          $v.form.$each.$iter[index].condition_value.$error
                        "
                        :noPleaseSelect="noPleaseSelect"
                        v-model="item.condition_value"
                        :indexCondition="index"
                        @onDataChange="selectMemberLevel"
                        valueField="id"
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option value="" disabled
                            >-- Please Select Type --</b-form-select-option
                          >
                        </template>
                      </InputSelectAutomation>
                    </b-col>
                    <b-col md="2" class="p-0">
                      <b-button
                        variant="light"
                        v-if="
                          form.filter(
                            (el) =>
                              el.condition_type_id != 12 &&
                              el.condition_type_id != 13 &&
                              el.condition_type_id != 14
                          ).length > 1
                        "
                        @click="removeCondition(index)"
                      >
                        <b-icon size="sm" icon="trash-fill" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row v-if="item.condition_type_id === 2">
                    <b-col md="10">
                      <InputSelectAutomation
                        v-bind:options="gender"
                        :value="item.condition_value"
                        :noPleaseSelect="noPleaseSelect"
                        :v="$v.form.$each.$iter[index].condition_value"
                        :isValidate="
                          $v.form.$each.$iter[index].condition_value.$error
                        "
                        v-model="item.condition_value"
                        :indexCondition="index"
                        @onDataChange="selectGender"
                        valueField="id"
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option value="" disabled
                            >-- Please Select Type --</b-form-select-option
                          >
                        </template>
                      </InputSelectAutomation>
                    </b-col>
                    <b-col md="2" class="p-0">
                      <b-button
                        variant="light"
                        v-if="
                          form.filter(
                            (el) =>
                              el.condition_type_id != 12 &&
                              el.condition_type_id != 13 &&
                              el.condition_type_id != 14
                          ).length > 1
                        "
                        @click="removeCondition(index)"
                      >
                        <b-icon size="sm" icon="trash-fill" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row v-if="item.condition_type_id === 17">
                    <b-col md="10">
                      <InputSelectAutomation
                        v-bind:options="productList"
                        :value="item.condition_value"
                        :noPleaseSelect="noPleaseSelect"
                        :v="$v.form.$each.$iter[index].condition_value"
                        :isValidate="
                          $v.form.$each.$iter[index].condition_value.$error
                        "
                        v-model="item.condition_value"
                        :indexCondition="index"
                        @onDataChange="selectGender"
                        valueField="id"
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option value="" disabled
                            >-- Please Select Type --</b-form-select-option
                          >
                        </template>
                      </InputSelectAutomation>
                    </b-col>
                    <b-col md="2" class="p-0">
                      <b-button
                        variant="light"
                        v-if="
                          form.filter(
                            (el) =>
                              el.condition_type_id != 12 &&
                              el.condition_type_id != 13 &&
                              el.condition_type_id != 14
                          ).length > 1
                        "
                        @click="removeCondition(index)"
                      >
                        <b-icon size="sm" icon="trash-fill" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="
                      item.condition_type_id === 3 ||
                      item.condition_type_id === 4 ||
                      item.condition_type_id === 5 ||
                      item.condition_type_id === 6 ||
                      item.condition_type_id == 30
                    "
                  >
                    <b-col md="5">
                      <InputSelectAutomation
                        v-bind:options="operatorList"
                        :value="item.operation_type_id"
                        :v="$v.form.$each.$iter[index].operation_type_id"
                        :isValidate="
                          $v.form.$each.$iter[index].operation_type_id.$error
                        "
                        :noPleaseSelect="noPleaseSelect"
                        v-model="item.operation_type_id"
                        :indexCondition="index"
                        @onDataChange="selectOperation"
                        valueField="id"
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option value="" disabled
                            >-- Please Select Type --</b-form-select-option
                          >
                        </template>
                      </InputSelectAutomation>
                    </b-col>
                    <b-col md="5">
                      <b-form-input
                        v-model="item.condition_value"
                        :placeholder="
                          item.condition_type_id === 6 ||
                          item.condition_type_id == 30
                            ? 'Value'
                            : 'Valid Days '
                        "
                        type="number"
                        :class="
                          $v.form.$each.$iter[index].condition_value.$error
                            ? 'border-error'
                            : ''
                        "
                      ></b-form-input>
                      <div
                        v-if="$v.form.$each.$iter[index].condition_value.$error"
                        class="text-error"
                      >
                        Please input.
                      </div>
                    </b-col>
                    <b-col md="2" class="p-0">
                      <b-button
                        variant="light"
                        v-if="
                          form.filter(
                            (el) =>
                              el.condition_type_id != 12 &&
                              el.condition_type_id != 13 &&
                              el.condition_type_id != 14
                          ).length > 1
                        "
                        @click="removeCondition(index)"
                      >
                        <b-icon size="sm" icon="trash-fill" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="
                      item.condition_type_id === 8 ||
                      item.condition_type_id === 9
                    "
                  >
                    <b-col md="4">
                      <InputSelectAutomation
                        v-bind:options="operatorList"
                        :value="item.operation_type_id"
                        :v="$v.form.$each.$iter[index].operation_type_id"
                        :isValidate="
                          $v.form.$each.$iter[index].operation_type_id.$error
                        "
                        :noPleaseSelect="noPleaseSelect"
                        v-model="item.operation_type_id"
                        :indexCondition="index"
                        @onDataChange="selectOperation"
                        valueField="id"
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option value="" disabled
                            >-- Please Select Type --</b-form-select-option
                          >
                        </template>
                      </InputSelectAutomation>
                    </b-col>
                    <b-col md="6">
                      <div class="input-container">
                        <datetime
                          type="datetime"
                          :class="
                            $v.form.$each.$iter[index].condition_value.$error
                              ? 'date-picker mb-0 border-error'
                              : 'date-picker mb-0'
                          "
                          :input-style="styleDatetime"
                          v-model="item.condition_value"
                          placeholder="DD/MM/YYYY (HH:MM)"
                          format="dd/MM/yyyy (HH:mm)"
                          value-zone="Asia/Bangkok"
                          ref="transferDateStart"
                        >
                        </datetime>
                        <div
                          :class="'icon-primary text-right'"
                          @click="$refs.transferDateStart.isOpen = true"
                        >
                          <font-awesome-icon
                            icon="calendar-alt"
                            :class="'pointer color-primary'"
                            color="#B41BB4"
                          />
                        </div>
                      </div>
                      <div
                        v-if="$v.form.$each.$iter[index].condition_value.$error"
                        class="text-error"
                      >
                        Please input.
                      </div>
                    </b-col>
                    <b-col md="2" class="p-0">
                      <b-button
                        variant="light"
                        v-if="
                          form.filter(
                            (el) =>
                              el.condition_type_id != 12 &&
                              el.condition_type_id != 13 &&
                              el.condition_type_id != 14
                          ).length > 1
                        "
                        @click="removeCondition(index)"
                      >
                        <b-icon size="sm" icon="trash-fill" />
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-row v-if="item.condition_type_id === 7">
                    <b-col md="10">
                      <InputSelectAutomation
                        v-bind:options="privilegeList"
                        :value="item.condition_value"
                        :v="$v.form.$each.$iter[index].condition_value"
                        :isValidate="
                          $v.form.$each.$iter[index].condition_value.$error
                        "
                        :noPleaseSelect="noPleaseSelect"
                        v-model="item.condition_value"
                        :indexCondition="index"
                        @onDataChange="selectPrivilegeCode"
                        valueField="id"
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option value="" disabled
                            >-- Please Select Type --</b-form-select-option
                          >
                        </template>
                      </InputSelectAutomation>
                    </b-col>
                    <b-col md="2" class="p-0">
                      <b-button
                        variant="light"
                        v-if="
                          form.filter(
                            (el) =>
                              el.condition_type_id != 12 &&
                              el.condition_type_id != 13 &&
                              el.condition_type_id != 14
                          ).length > 1
                        "
                        @click="removeCondition(index)"
                      >
                        <b-icon size="sm" icon="trash-fill" />
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-row
                    class="no-gutters"
                    v-if="item.condition_type_id === 10"
                  >
                    <b-col md="10" class="justify-content-start">
                      <UploadFileSendMessage
                        textFloat="File"
                        placeholder="Please Choose File"
                        format="excel"
                        name="file"
                        text="*Please upload only file .xlsx less than 10 MB"
                        isRequired
                        v-on:onFileChange="onFileChange"
                        :indexCondition="index"
                        v-on:delete="deleteFile"
                        :fileName="filename"
                        id="uploadfile_sendmessage"
                      />
                    </b-col>
                    <b-col md="2" class="p-0">
                      <b-button
                        variant="light"
                        v-if="
                          form.filter(
                            (el) =>
                              el.condition_type_id != 12 &&
                              el.condition_type_id != 13 &&
                              el.condition_type_id != 14
                          ).length > 1
                        "
                        @click="removeCondition(index)"
                      >
                        <b-icon size="sm" icon="trash-fill" />
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-row v-if="item.condition_type_id === 11">
                    <b-col md="10">
                      <InputSelectAutomation
                        v-bind:options="stampList"
                        :value="item.condition_value"
                        :v="$v.form.$each.$iter[index].condition_value"
                        :isValidate="
                          $v.form.$each.$iter[index].condition_value.$error
                        "
                        :noPleaseSelect="noPleaseSelect"
                        v-model="item.condition_value"
                        :indexCondition="index"
                        @onDataChange="selectPrivilegeCode"
                        valueField="id"
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option value="" disabled
                            >-- Please Select Type --</b-form-select-option
                          >
                        </template>
                      </InputSelectAutomation>
                    </b-col>
                    <b-col md="2" class="p-0">
                      <b-button
                        variant="light"
                        v-if="
                          form.filter(
                            (el) =>
                              el.condition_type_id != 12 &&
                              el.condition_type_id != 13 &&
                              el.condition_type_id != 14
                          ).length > 1
                        "
                        @click="removeCondition(index)"
                      >
                        <b-icon size="sm" icon="trash-fill" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row v-if="item.condition_type_id === 31">
                    <b-col md="10">
                      <InputSelectAutomation
                        v-bind:options="redeemItemList"
                        :value="item.condition_value"
                        :v="$v.form.$each.$iter[index].condition_value"
                        :isValidate="
                          $v.form.$each.$iter[index].condition_value.$error
                        "
                        :noPleaseSelect="noPleaseSelect"
                        v-model="item.condition_value"
                        :indexCondition="index"
                        @onDataChange="selectMemberLevel"
                        valueField="id"
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option value="" disabled
                            >-- Please Select Type --</b-form-select-option
                          >
                        </template>
                      </InputSelectAutomation>
                    </b-col>
                    <b-col md="2" class="p-0">
                      <b-button
                        variant="light"
                        v-if="
                          form.filter(
                            (el) =>
                              el.condition_type_id != 12 &&
                              el.condition_type_id != 13 &&
                              el.condition_type_id != 14
                          ).length > 1
                        "
                        @click="removeCondition(index)"
                      >
                        <b-icon size="sm" icon="trash-fill" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row v-if="item.condition_type_id === 34">
                    <b-col md="10">
                      <InputSelectAutomation
                        v-bind:options="[
                          { id: '1', name: 'Yes' },
                          { id: '0', name: 'No' },
                        ]"
                        :value="item.condition_value"
                        :v="$v.form.$each.$iter[index].condition_value"
                        :isValidate="
                          $v.form.$each.$iter[index].condition_value.$error
                        "
                        :noPleaseSelect="noPleaseSelect"
                        v-model="item.condition_value"
                        :indexCondition="index"
                        valueField="id"
                        @onDataChange="selectProfileComplete"
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option value="" disabled
                            >-- Please Select Type --</b-form-select-option
                          >
                        </template>
                      </InputSelectAutomation>
                    </b-col>
                    <b-col md="2" class="p-0">
                      <b-button
                        variant="light"
                        v-if="
                          form.filter(
                            (el) =>
                              el.condition_type_id != 12 &&
                              el.condition_type_id != 13 &&
                              el.condition_type_id != 14
                          ).length > 1
                        "
                        @click="removeCondition(index)"
                      >
                        <b-icon size="sm" icon="trash-fill" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="mt-3">
              <button @click.prevent="addRuleCondition" class="btn-add">
                <font-awesome-icon icon="plus-square" class="mr-2" />
                <span class="add-new">Create a New List</span>
              </button>
            </div>
          </div>
          <DisplayCampaignField
            v-show="isSelected == 1 && formMain.event_type_id == 3"
            ref="campaignFieldCondition"
            :form="listCondition"
            :v="v"
            :campaignFieldConditions="campaignFieldConditions"
          />
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
              :disabled="isLoading"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              block
              variant="light"
              :disabled="isLoading"
              class="text-body rounded-pill btn-main"
              @click.prevent="submitCondition"
            >
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import InputSelectAutomation from "@/components/inputs/InputSelectAutomation";
import DisplayCampaignField from "@/components/automation/component/DisplayCampaignField";
import UploadFileSendMessage from "@/components/automation/segmentation/UploadFileSendMessage";
import OtherLoading from "@/components/loading/OtherLoading";
import { required, requiredIf, url } from "vuelidate/lib/validators";
export default {
  components: {
    InputSelectAutomation,
    UploadFileSendMessage,
    OtherLoading,
    DisplayCampaignField,
  },
  props: {
    privilegeList: {
      required: true,
      type: Array,
    },
    redeemItemList: {
      required: true,
      type: Array,
    },
    stampList: {
      required: false,
      // type: Array,
    },
    conditionList: {
      required: true,
      type: Array,
    },
    memberLevel: {
      required: true,
      type: Array,
    },
    gender: {
      required: true,
      type: Array,
    },
    operatorList: {
      required: true,
      type: Array,
    },
    listCondition: {
      required: false,
      type: Array | undefined,
    },
    productList: {
      required: true,
    },
    v: {
      required: true,
      type: Object,
    },
    selected: {
      required: true,
      type: Number,
    },
    formMain: {
      required: false,
      type: Object,
    },
    campaignFieldConditions: {
      required: false,
      type: Array,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      condition_type_id: 0,
      noPleaseSelect: true,
      isSelected: this.selected,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      filename: "",
      fileBase64: "",
      isLoading: false,
      form: [],
    };
  },
  validations: {
    form: {
      $each: {
        condition_type_id: {
          required: requiredIf(function (item) {
            return !item.condition_type_id;
          }),
        },
        operation_type_id: {
          required: requiredIf(function (item) {
            return !item.operation_type_id;
          }),
        },
        condition_value: {
          required: requiredIf(function (item) {
            return !item.condition_value;
          }),
        },
      },
    },
  },
  watch: {
    listCondition(val) {
      this.form = JSON.parse(JSON.stringify(val));
    },
  },
  methods: {
    show() {
      if (this.isSelected === 1) {
        this.$refs.campaignFieldCondition.$v.$reset();
        this.$v.$reset();
        var obj = this.listCondition.filter(
          (el) =>
            el.condition_type_id != 12 &&
            el.condition_type_id != 13 &&
            el.condition_type_id != 14
        );
        let tempObj = [...this.listCondition];
        if (obj.length == 0) {
          tempObj.push({
            condition_type_id: this.conditionList[0].id,
            operation_type_id: "",
            condition_value: "",
            condition_type_name: this.conditionList[0].name,
          });
        }

        this.form = JSON.parse(JSON.stringify(tempObj));
      }

      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    addRuleCondition() {
      this.form.push({
        condition_type_id: this.conditionList[0].id,
        operation_type_id: "",
        condition_value: "",
        condition_type_name: this.conditionList[0].name,
      });
    },
    selectOperationTypeId(...value) {
      this.form[value[1]].operation_type_id = value[0];
      this.form[value[1]].operation_type_name = value[2];
    },
    selectMemberLevel(...value) {
      this.form[value[1]].condition_value = value[0];
      this.form[value[1]].operation_type_id = 3;
    },
    selectProfileComplete(...value) {
      this.form[value[1]].condition_value = value[0];
      this.form[value[1]].operation_type_id = 3;
    },
    selectPrivilegeCode(...value) {
      this.form[value[1]].condition_value = value[0];
      this.form[value[1]].operation_type_id = 3;
    },
    removeCondition(index) {
      this.form.splice(index, 1);
    },
    selectGender(...value) {
      this.form[value[1]].condition_value = value[0];
      this.form[value[1]].operation_type_id = 3;
    },
    selectCondition(...value) {
      this.form[value[1]].condition_type_id = value[0];
      this.form[value[1]].condition_value = "";
      this.form[value[1]].condition_type_name = value[2];
    },
    selectOperation(...value) {
      this.form[value[1]].operation_type_id = value[0];
      this.form[value[1]].operation_type_name = value[2];
    },
    submitCondition() {
      console.log(this.form);
      if (this.isSelected) {
        if (this.isSelected === 2) {
          this.$emit("settingCondition", this.isSelected);
          this.hide();
        } else {
          this.$v.form.$touch();

          this.$refs.campaignFieldCondition.$v.$touch();

          if (
            this.$v.form.$error &&
            this.$refs.campaignFieldCondition.$v.$error
          ) {
            return;
          }
          this.$emit("setForm", this.form);

          var obj = this.form.filter(
            (el) => el.condition_type_id != 12 && el.condition_type_id != 13
          );
          let objCamp = this.$refs.campaignFieldCondition.fieldList.map(
            (el) => {
              if (el.value && el.optionsValue)
                return {
                  condition_type_id: el.condition_id,
                  operation_type_id: 3,
                  condition_value: el.optionsValue,
                  condition_type_name: el.value,
                };
            }
          );

          let objTemp = [...obj, ...objCamp.filter((el) => el)];
          objTemp = objTemp.filter((el) => el.condition_value);
          this.$emit("settingCondition", this.isSelected, objTemp);
          this.hide();
        }
      } else {
        this.$swal("Please set conditions", {
          icon: "warning",
        });
      }
    },
    setCondition() {
      if (this.isSelected === 1) {
        if (this.form.length < 1) {
          this.addRuleCondition();
          // this.form = JSON.parse(JSON.stringify(this.listCondition));
        }
      }
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    onFileChange(...file) {
      this.filename = file[0].name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        this.formMain.import_condition = reader.result.substring(
          str.length,
          reader.result.length
        );
        this.checkFormFile(file);
      };
    },
    async checkFormFile(file) {
      this.isLoading = true;
      let body = {
        excel_file: this.formMain.import_condition,
      };
      await this.$store.dispatch("checkFileExcel", body);
      const data = this.$store.state.automation.stateCheckFileExcel;
      if (data.result === 1) {
        if (data.detail > 0) {
          this.setFormFile(file);
        } else {
          this.isLoading = false;
          this.formMain.import_condition = "";
          this.filename = null;
          const msg = "Please check the information throughly";
          this.$swal(msg, {
            icon: "warning",
          });
        }
      } else {
        this.isLoading = false;
        this.formMain.import_condition = "";
        this.filename = null;
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    setFormFile(file) {
      this.isLoading = false;
      this.form[file[1]].condition_value = file[0].name;
      this.form[file[1]].operation_type_id = 3;
    },
    deleteFile(value) {
      this.filename = null;
      this.formMain.import_condition = "";
      this.isDisable = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  color: var(--font-color);
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}

::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.add-new {
  text-decoration: underline;
}
::v-deep .btn-light {
  background-color: transparent;
  border: none;
}
.display-inline {
  display: inline-block;
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.text-error {
  color: red;
}
.border-error {
  border-color: red;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
::v-deep .date-picker .vdatetime-input {
  margin-bottom: 0;
  padding: 2px 10px;
}
</style>
